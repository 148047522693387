// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
// import axios from 'utils/axios';
import axios from 'axios';
import { dispatch } from '../index';
import { openSnackbar } from './snackbar';
import configData from '../../config';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    report: null,
    reports: [],
    campus: '',
    campuses: [],
    isLoading: true
};

const slice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        },

        // GET REPORT
        getReportSuccess(state, action) {
            state.report = action.payload;
            state.isLoading = false;
        },

        // GET REPORTS
        getReportsSuccess(state, action) {
            state.reports = action.payload;
            state.isLoading = false;
        },

        // SET LOADING
        setLoadingSuccess(state, action){
            state.isLoading = action.payload
        },

        // GET CAMPUS
        getCampusSuccess(state, action) {
            state.campus = action.payload;
            state.isLoading = false;
        },

        // GET CAMPUSES
        getCampusesSuccess(state, action) {
            state.campuses = action.payload;
            state.isLoading = false;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getCampuses() {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess(true))
            const url = configData.API_SERVER + 'campus';
            const response = await axios.get(url,  { headers: { Authorization: 'Token ' + configData.TOKEN, } }); // this token can be found in auttoken_token table. if it doesn't exist. create one.
            dispatch(slice.actions.getCampusesSuccess(response.data.results))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.data.message,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function getCampus(campus) {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess(true))
            dispatch(slice.actions.getCampusSuccess(campus))
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.data.message,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}

export function createReport(values) {
    return async () => {
        try {
            dispatch(slice.actions.setLoadingSuccess(true))
            const url = configData.API_SERVER + 'bullying-report';
            const data = new FormData();
            
            data.append('campus', values.campus.toString());
            data.append('incident_date', values.startDate.toISOString().split('T')[0]);
            data.append('incident_location', values.location);
            data.append('reporter_name', values.reporterName);
            data.append('reporter_contact', values.contact);
            data.append('victim_name', values.victimName);
            data.append('victim_grade', values.victimGradeLevel);
            data.append('alleged_bully_name', values.allegedBullyName);
            data.append('alleged_bully_grade', values.allegedBullyGradeLevel);
            data.append('description', values.desc);
            data.append('witnesses', values.witnesses);
            data.append('comments', values.comments);
            data.append('is_active', true);

            const config = {
                headers: {
                'Authorization': 'Token ' + configData.TOKEN,
                }
            };
            
            const response = await axios.post(url, data, config);
            dispatch(slice.actions.getReportSuccess(response.data));
            
            dispatch(
                openSnackbar({
                    open: true,
                    // eslint-disable-next-line prefer-template
                    message: "We've recieved your submission",
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    }
                })
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                dispatch(slice.actions.hasError(error.response.data));
                dispatch(
                    openSnackbar({
                        open: true,
                        // eslint-disable-next-line prefer-template
                        message: error.response.data,
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'The request was made but no response was received',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Something happened in setting up the request that triggered an error',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        }
                    })
                );
            }
        }
    };
}