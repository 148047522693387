import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MinimalLayout from './../layout/MinimalLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';

// dashboard routing
const Report = Loadable(lazy(() => import('../views/dashboard/Report')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();
    return (
        <Route path={['/dashboard/bullying-form',]}>
            <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                    {/* <AuthGuard> */}
                        <Route path="/dashboard/bullying-form" component={Report} />
                    {/* </AuthGuard> */}
                </Switch>
            </MinimalLayout>
        </Route>
    );
};

export default MainRoutes;
